<template>
  <div class="contribution-card">
    <van-sticky>
      <van-config-provider :theme-vars="themeVars">
        <van-nav-bar :title="pageTitle" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
      </van-config-provider>
      <van-tabs v-model:active="tabActive" type="card">
        <van-tab :title="$t('tabTitle.receiptFragment')"></van-tab>
        <van-tab :title="$t('tabTitle.contact')">
          <van-tabs v-model:active="subTabActive">
            <van-tab :title="$t('tabTitle.cCardPurchase')"></van-tab>
            <van-tab :title="$t('tabTitle.cCardPurchaseUsing')"></van-tab>
            <van-tab :title="$t('tabTitle.cCardPurchaseIsUsed')"></van-tab>
          </van-tabs>
        </van-tab>
      </van-tabs>
    </van-sticky>
    <div :class="`tab-content tab${tabActive}`">
      <template v-if="tabActive === 0">
        <van-pull-refresh v-model="refreshing" @refresh="refreshData">
          <div class="order-list">
            <van-list
              v-if="dataList.length >0"
              v-model:loading="loading"
              :finished="finished"
              :finished-text="$t('common.noMore')"
              @load="loadData"
            >
              <!-- <div class="card-block" v-for="item in dataList" :key="item.id" @click="goToDetail(item)">
                <div class="category-title">
                  <div class="line" :style="`background: ${item.color};`"></div>
                  <p>{{item.name}}</p>
                  <div v-if="item.limit" class="right-text">{{item.now || 0}} / {{item.limit}}</div>
                </div>
                <van-image :src="item.imgUrl" />
              </div> -->
              <div class="card-block" v-for="item in dataList" :key="item.id" @click="goToDetail(item)">
                <div class="category-title">
                  <div class="line" :style="`background: ${item.color};`"></div>
                  <p>{{item.name}}</p>
                  <div v-if="item.limit" class="right-text">{{item.now || 0}} / {{item.limit}}</div>
                </div>
                <div class="card-img-block" :style="`background: url(${item.imgUrl}) 0 0/100% auto no-repeat;`">
                  <span class="card-name">{{item.cardName || item.name}}</span>
                  <div class="detail-box">
                    <div class="detail-col col1">
                      <p class="detail-label">{{item.deposit ? `${item.deposit} ${$AppData.config.appCoin}` : $t('field.available2')}}</p>
                      <p class="detail-value">{{$t('field.needDeposit')}}</p>
                    </div>
                    <div class="divider"></div>
                    <div class="detail-col col2">
                      <p class="detail-label">{{item.useDay || $t('field.available2')}}</p>
                      <p class="detail-value">{{$tc('field.useDay',item.useDay)}}</p>
                    </div>
                    <div class="divider"></div>
                    <div class="detail-col col3">
                      <p class="detail-label">{{item.contribution || $t('field.available2')}}</p>
                      <p class="detail-value">{{$t('field.dayContribution')}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </van-list>
            <div v-else>
              <van-empty :description="$t('common.noData')"></van-empty>
            </div>
          </div>
        </van-pull-refresh>
      </template>
      <template v-else>
        <van-pull-refresh v-model="refreshing" @refresh="refreshData">
          <div class="order-list">
            <van-list
              v-if="dataList.length >0"
              v-model:loading="loading"
              :finished="finished"
              :finished-text="$t('common.noMore')"
              @load="loadData"
            >
              <div :class="`journal-block bg${index % 3 + 1}`" v-for="(item, index) in dataList" :key="item.sn">
                <div class="card-title">{{item.ccName}}</div>
                <van-image v-if="subTabActive == 1" class="stamp-img" src="/images/contribution/stamp.png" />
                <div v-if="subTabActive !== 2" class="journal-detail">
                  <van-cell :title="$t('field.orderNumber')" :value="item.sn" :border="false" class="journal-detail-row" title-class="journal-order" value-class="journal-sn" />
                  <van-cell :title="$t('field.purchaseTime')" :value="item.createTimeStr" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                  <van-cell :title="$t('field.status')" :value="item.statusStr" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                  <van-cell :title="$t('field.deposit')" :value="`${item.deposit}${$AppData.config.appCoin}`"  :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                  <van-cell :title="$t('field.depositStatus')" :value="item.depositReturn ? $t('status.returned') : $t('status.notReturned')" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                  <van-cell :title="$t('field.depositRefundTime')" :value="item.returnTimeStr" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                  <van-cell :title="$t('field.dayContribution')" :value="item.contribution" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                  <van-cell :title="$tc('field.useDay',item.useDay)" :value="item.useDay" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                  <van-cell :title="$t('field.usingDay')" :value="item.usingDay" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                </div>
                <div v-else class="journal-detail">
                  <van-cell :title="$t('field.orderNumber')" :value="item.sn" :border="false" class="journal-detail-row" title-class="journal-order" value-class="journal-sn" />
                  <van-cell :title="$t('field.usingTime')" :value="item.modifyTimeStr" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                  <!-- <van-cell :title="$t('field.contribution')" :value="item.contribution" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" /> -->
                </div>
              </div>
            </van-list>
            <div v-else>
              <van-empty :description="$t('common.noData')"></van-empty>
            </div>
          </div>
        </van-pull-refresh>
      </template>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Toast } from 'vant';
import useClipboard from 'vue-clipboard3';
import i18n from '@/assets/lang/index';
import { queryCCardCategory, buyContributionCard, queryCCardPurchaseList, 
  queryCCardActiveList, queryCCardUseRecordList, } from "@/api";

export default {
  setup() {
    const { toClipboard } = useClipboard();
    const copy = async (text) => {
      try {
        await toClipboard(text);
        Toast(i18n.global.t('notify.copySuccess'));
      } catch (e) {
        console.error(e)
      }
    }
    
    const themeVars = {
      navBarBackgroundColor: 'transparent',
      navBarTitleTextColor: '#fff',
      navBarIconColor: '#fff',
    };
    
    const tabActive = ref(0);
    const subTabActive = ref(0);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const dataList = ref([]);
    return {
      copy,
      themeVars,
      tabActive,
      subTabActive,
      loading,
      finished,
      refreshing,
      dataList,
    };
  },
  
  data() {
    return {
      pageTitle: localStorage.getItem('pageName'),
      amount: '',
      appTip: '',
      current: 1,
    };
  },
  
  watch: {
    tabActive() {
      this.refreshing = true;
      this.refreshData();
    },
    subTabActive() {
      this.refreshing = true;
      this.refreshData();
    },
  },
  
  mounted() {
    this.fetchOrderList();
  },

  methods: {
    fetchOrderList() {
      let doPost = '';
      if(this.tabActive === 0) {
        doPost = queryCCardCategory;
      } else {
        if(this.subTabActive === 0) {
          doPost = queryCCardPurchaseList;
        } else if(this.subTabActive === 1) {
          doPost = queryCCardActiveList;
        } else {
          doPost = queryCCardUseRecordList;
        }
      }
      doPost({
        pageSizeStr: 10,
        pageNumberStr: this.current,
      }).then(res => {
        this.dataList = this.dataList.concat(res.data || []);
        this.current = res.pageNumber + 1;
        this.loading = false;
        if (res.totalNumber <= res.pageNumber * res.pageSize) {
          this.finished = true;
        }
      });
    },
    loadData() {
      if (this.refreshing) {
        this.dataList = [];
        this.current = 1;
        this.refreshing = false;
      }
      this.fetchOrderList();
    },
    refreshData() {
      // 清空列表数据
      this.finished = false;
            
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.loadData();
    },
    goToDetail(item) {
      this.$router.push(`/contribution/category/${item.id}/card`);
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.contribution-card {
  height: 100vh;
  width: 100vw;
  background: #fff url(/images/contribution/top-bg.png) no-repeat;
  background-size: 100% auto;
  overflow: hidden;
  .van-tabs--card>.van-tabs__wrap { height: 60px; }
  .van-tabs__nav--card {
    height: 60px;
    background: transparent;
  }
  .van-tab--card {
    color: #fff;
    font-size: 18px;
    font-weight: normal;
    &.van-tab--active {
      font-size: 23px;
      font-weight: 500;
      background: transparent;
    }
  }
  .van-tabs--line {
    .van-tabs__wrap { height: 32px; }
    .van-tabs__nav { background: transparent; }
    .van-tab__text {
      color: #fff;
      line-height: 14px;
    }
    .van-tabs__line {
      width: 12%;
      background: linear-gradient(to right, #2D62FF, #1F9F98);
    }
  }
  
  .tab-content {
    max-height: calc(100vh - 140px);
    overflow-y: scroll;
    &.tab0 {
      max-height: calc(100vh - 108px);
    }
  }
  .order-list { padding: 12px; }
  .order-list .journal-block {
    padding: 16px 12px;
    border-radius: 10px;
    margin-top: 6px;
    &.bg1 {
      background: #fff url(/images/contribution/card-bg1.png) no-repeat;
      background-size: 100% auto;
    }
    &.bg2 {
      background: #fff url(/images/contribution/card-bg2.png) no-repeat;
      background-size: 100% auto;
    }
    &.bg3 {
      background: #fff url(/images/contribution/card-bg3.png) no-repeat;
      background-size: 100% auto;
    }
    .card-title {
      display: inline-block;
      line-height: 22px;
      padding: 6px 16px;
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      color: #fff;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      border-bottom: none;
    }
    .stamp-img {
      position: absolute;
      top: 12px;
      right: 24px;
      width: 51px;
    }
    .journal-detail {
      margin: 20px 0 4px;
      padding: 6px 20px;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }
    .journal-detail-row { 
      border: none;
      .van-cell__title {
        font-weight: normal;
        color: #fff;
      }
      .van-cell__value {
        font-weight: bold;
        color: #fff;
      }
    }
  }
  .journal-order {
    flex: 2;
  }
  .journal-sn {
    flex: 3;
  }
  
  .card-block {
    margin-bottom: 16px;
    .category-title {
      display: flex;
      align-items: center;
      line-height: 22px;
      margin-bottom: 12px;
      font-size: 20px;
      font-weight: 500;
      color: #748C9B;
      .line {
        width: 4px;
        height: 12px;
        border-radius:2px;
        margin-right: 8px;
      }
      p { flex: 1; }
      .right-text {
        width: 60px;
        font-size: 18px;
        text-align: right;
      }
    }
    // .van-image,
    // .van-image img{
    //   display: block;
    //   width: 100%;
    //   min-height: 60px;
    //   border-radius: 9px;
    // }
  }
  .card-img-block {
    min-height: 152px;
    padding: 10px;
    margin-bottom: 14px;
    border-radius: 4px;
    box-sizing: border-box;
    .card-name {
      display: inline-block;
      line-height: 27px;
      padding: 0 8px;
      font-size: 16px;
      font-weight: 500;
      margin: 5px 0 32px;
      color: #fff;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.16);
    }
    .detail-box {
      display: flex;
      align-items: center;
      color: #fff;
      font-weight: 500;
      margin: 0 4px;
      padding: 12px 0;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.16);
      .detail-col {
        text-align: center;
        &.col1 { width: 104px; }
        &.col2 { width: 98px; }
        &.col3 { flex: 1; }
        .detail-label {
          min-height: 21px;
          font-size: 18px;
          margin-bottom: 8px;
        }
        .detail-value {
          font-size: 12px;
        }
      }
    }
    .divider {
      width: 1px;
      height: 28px;
      background: #fff;
    }
  }
}
</style>
